var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "examIndex" },
    [
      _c("div", { staticClass: "fixed" }, [
        _c("ul", { staticClass: "tabs" }, [
          _c(
            "li",
            {
              class: { on: _vm.tab == 2 },
              on: {
                click: function($event) {
                  return _vm.changeTab(2)
                }
              }
            },
            [_vm._v("我的考试")]
          ),
          _vm.isShow
            ? _c(
                "li",
                {
                  class: { on: _vm.tab == 1 },
                  on: {
                    click: function($event) {
                      return _vm.changeTab(1)
                    }
                  }
                },
                [_vm._v("我的练习")]
              )
            : _vm._e()
        ]),
        _c(
          "p",
          {
            staticClass: "title",
            on: {
              click: function($event) {
                _vm.showPicker = true
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.status) + " "),
            _c("i", { staticClass: "iconfont iconjiantou-xianxing" })
          ]
        )
      ]),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showPicker,
            callback: function($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              columns: _vm.columns[_vm.tab - 1],
              "value-key": "text"
            },
            on: {
              cancel: function($event) {
                _vm.showPicker = false
              },
              confirm: _vm.onConfirm
            }
          })
        ],
        1
      ),
      _c("PracticeList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab == 1 ? true : false,
            expression: "tab == 1 ? true : false"
          }
        ],
        ref: "PracticeList"
      }),
      _c("ExamList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab == 2 ? true : false,
            expression: "tab == 2 ? true : false"
          }
        ],
        ref: "ExamList"
      }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _vm._v("© 版权所有 "),
      _c("a", { attrs: { href: "https://beian.miit.gov.cn/" } }, [
        _vm._v("鄂ICP备2020017212号-1")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }