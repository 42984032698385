var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "examList" },
    [
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": _vm.finishedText },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        _vm._l(_vm.list, function(item) {
          return _c(
            "div",
            { key: item, staticClass: "listItme" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "/exam/introduce",
                      query: { arrangeId: item.arrangeId }
                    }
                  }
                },
                [
                  _c("h4", [_vm._v(_vm._s(item.arrangeName))]),
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        "安排时间：" + _vm._s(_vm.releaseDate(item.releaseDate))
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "题目数量：" + _vm._s(item.totalQuestionQty) + " 题"
                      )
                    ])
                  ]),
                  _c("div", [
                    item.isControlSameTime
                      ? _c("p", [
                          _vm._v(
                            " 统一交卷：" +
                              _vm._s(
                                _vm.controlSubmitTime(item.controlSubmitTime)
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("p", [
                      _vm._v(
                        "考试时长：" +
                          _vm._s(
                            item.duration ? item.duration + "分钟" : "不限时"
                          )
                      )
                    ])
                  ]),
                  item.isControlDate
                    ? _c("p", [
                        _vm._v(
                          "开放时间：" +
                            _vm._s(_vm.examDate(item.startDate, item.endDate))
                        )
                      ])
                    : _vm._e(),
                  !(
                    item.isShowScore &&
                    (item.userStatus == "Done" ||
                      (item.isResit == 1 &&
                        item.submitTime != "" &&
                        item.submitTime != null))
                  )
                    ? _c("p", [
                        _vm._v(" 指派人：" + _vm._s(item.createUserName) + " ")
                      ])
                    : _vm._e(),
                  item.isShowScore &&
                  (item.userStatus == "Done" ||
                    (item.isResit == 1 &&
                      item.submitTime != "" &&
                      item.submitTime != null))
                    ? _c("div", { staticClass: "score" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.submitTime(item.submitTime)) + " "
                          ),
                          _c("i", [
                            _vm._v(" " + _vm._s(item.score.toFixed(1)))
                          ]),
                          _vm._v(
                            "分/" + _vm._s(item.maxScore.toFixed(1)) + "分 "
                          )
                        ]),
                        _c("span", [_vm._v("查看")])
                      ])
                    : _vm._e(),
                  _c("div", [
                    _c("div", { staticClass: "tags" }, [
                      _vm.setExamProcessStatus(
                        item.userStatus,
                        item.arrangeStatus,
                        item.isAllowRepeat,
                        item.isControlDate,
                        item.startDate,
                        item.endDate,
                        item.isControlSameTime,
                        item.controlSubmitTime,
                        item.usedExamTimes,
                        item.examTimes
                      )
                        ? _c(
                            "button",
                            {
                              staticStyle: {
                                color: "#52c41a",
                                border: "1px solid #b7eb8f",
                                background: "#f6ffed"
                              }
                            },
                            [_vm._v(" 进行中 ")]
                          )
                        : _vm._e(),
                      _vm.tag1(item)
                        ? _c(
                            "button",
                            {
                              staticStyle: {
                                color: "#666",
                                background: "#dddddd",
                                border: "1px solid #b7b6b6"
                              }
                            },
                            [_vm._v(" 未参加 ")]
                          )
                        : _vm._e(),
                      _vm.tag2(item)
                        ? _c(
                            "button",
                            {
                              staticClass: "tag",
                              staticStyle: {
                                color: "#666",
                                background: "#dddddd",
                                border: "1px solid #b7b6b6"
                              }
                            },
                            [_vm._v(" 未开考 ")]
                          )
                        : _vm._e(),
                      item.freeLevel == 1
                        ? _c(
                            "button",
                            {
                              staticClass: "tag",
                              staticStyle: {
                                color: "#318df0",
                                background: "#e6f7ff",
                                border: "1px solid #91d5ff"
                              }
                            },
                            [_vm._v(" 全行公开 ")]
                          )
                        : _vm._e(),
                      item.isAllowRepeat === 1
                        ? _c(
                            "button",
                            {
                              staticClass: "tag",
                              staticStyle: {
                                color: "#ee7805",
                                background: "#fff9f2",
                                border: "1px solid #ee7805"
                              }
                            },
                            [_vm._v(" 多次考 ")]
                          )
                        : _vm._e(),
                      item.isResit === 1
                        ? _c(
                            "button",
                            {
                              staticClass: "tag",
                              staticStyle: {
                                color: "#fa6a63",
                                background: "#fff1f0",
                                border: "1px solid #ffa39e"
                              }
                            },
                            [_vm._v(" 补考 ")]
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "start",
                        attrs: { disabled: _vm.getStartBtnText(item, 0) },
                        on: {
                          click: function($event) {
                            return _vm.goExam(item)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.getStartBtnText(item, 1)) + " ")]
                    )
                  ])
                ]
              )
            ],
            1
          )
        }),
        0
      ),
      _vm.noData
        ? _c("div", { staticClass: "noData" }, [
            _c("img", {
              attrs: { src: require("../../../assets/noData.png"), alt: "" }
            }),
            _c("p", [_vm._v("无数据")])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }