<template>
  <div class="examIndex">
    <div class="fixed">
      <ul class="tabs">
        <li :class="{ on: tab == 2 }" @click="changeTab(2)">我的考试</li>
        <li :class="{ on: tab == 1 }" @click="changeTab(1)" v-if="isShow">我的练习</li>
      </ul>
      <p class="title" @click="showPicker = true">
        {{ status }} <i class="iconfont iconjiantou-xianxing"></i>
      </p>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns[tab - 1]" value-key="text" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
    <PracticeList ref="PracticeList" v-show="tab == 1 ? true : false" />
    <ExamList ref="ExamList" v-show="tab == 2 ? true : false" />
    <div class="footer">© 版权所有 <a href='https://beian.miit.gov.cn/'>鄂ICP备2020017212号-1</a></div>
  </div>
</template>

<script>
import { Picker, Popup } from "vant";
import ExamList from "./components/oteExamList";
import PracticeList from "./components/otePracticeList";
import { mapState, mapMutations } from "vuex";
export default {
  name: "examIndex",
  components: {
    "van-picker": Picker,
    "van-popup": Popup,
    ExamList,
    PracticeList
  },
  data () {
    return {
      showPicker: false,
      columns: [
        [
          {
            text: "全部",
            id: 0
          },
          {
            text: "未开始",
            id: 1
          },
          {
            text: "进行中",
            id: 2
          },
          {
            text: "已结束",
            id: 3
          }
        ],
        [
          {
            text: "全部考试",
            id: 999
          },
          {
            text: "正式考试",
            id: 0
          },
          {
            text: "模拟考试",
            id: 1
          }
        ]
      ],
      status: "全部",
      statusId: "",
      isShow: false
    };
  },
  computed: {
    ...mapState(["tab"])
  },
  /** url菜单配置
   *  isWit = 1 : 标题是"党建考试",其他是"考试"
   *  viewFlag = 1 :展示我的考试,隐藏我的练习
   */
  created () {
    this.isShow = this.$route.query.viewFlag == 1 ? false : true;
    if (this.$route.query.isWit == 1) {
      document.title = "党建考试";
    }
  },
  mounted () { },
  methods: {
    ...mapMutations(["updateTab"]),
    changeTab (e) {
      this.updateTab(e);
      this.status = ["全部", "全部考试"][e - 1];
      if (e == 1) {
        this.statusId = 0;
        this.$refs.PracticeList.getList(this.statusId, { pageNum: 1 });
      } else {
        this.statusId = "";
        this.$refs.ExamList.getList(this.statusId, { pageNum: 1 });
      }
    },
    onConfirm (value, index) {
      this.status = value.text;
      this.statusId = value.id === 999 ? "" : value.id;
      this.showPicker = false;

      if (this.tab == 1) {
        this.$refs.PracticeList.getList(this.statusId, { pageNum: 1 });
      } else {
        this.$refs.ExamList.getList(this.statusId, { pageNum: 1 });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.examIndex {
  font-size: 16px;
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-top: 160px;
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
  }
  .tabs {
    display: flex;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    color: #c0c4cc;
    li {
      flex: 1;
      text-align: center;
      border-bottom: 1px solid #e4e7ed;
      &.on {
        color: @blueTheme;
        border-bottom: 1px solid @blueTheme;
      }
    }
  }
  .title {
    font-size: 28px;
    color: @blueTheme;
    text-align: center;
    height: 100px;
    line-height: 100px;
  }
  .footer {
    // border: 1px solid;
    padding-bottom: 0px;
    width: 100%;
    padding-left: 24%;
  }
}
</style>
