<template>
  <div class="examList">
    <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
      <div class="listItme" v-for="item in list" :key="item">
        <router-link :to="{ path: '/exam/introduce', query: { arrangeId: item.arrangeId } }">
          <h4>{{ item.arrangeName }}</h4>
          <div>
            <p>安排时间：{{ releaseDate(item.releaseDate) }}</p>
            <p>题目数量：{{ item.totalQuestionQty }} 题</p>
          </div>
          <div>
            <p v-if="item.isControlSameTime">
              统一交卷：{{ controlSubmitTime(item.controlSubmitTime) }}
            </p>
            <p>考试时长：{{ item.duration ? item.duration + "分钟" : "不限时" }}</p>
          </div>
          <p v-if="item.isControlDate">开放时间：{{ examDate(item.startDate, item.endDate) }}</p>
          <p v-if="
              !(
                item.isShowScore &&
                (item.userStatus == 'Done' ||
                  (item.isResit == 1 && item.submitTime != '' && item.submitTime != null))
              )
            ">
            指派人：{{ item.createUserName }}
          </p>
          <div v-if="
              item.isShowScore &&
                (item.userStatus == 'Done' ||
                  (item.isResit == 1 && item.submitTime != '' && item.submitTime != null))
            " class="score">
            <p>
              {{ submitTime(item.submitTime) }} <i> {{ item.score.toFixed(1) }}</i>分/{{ item.maxScore.toFixed(1) }}分
            </p>
            <span>查看</span>
          </div>
          <div>
            <div class="tags">
              <button v-if="
                  setExamProcessStatus(
                    item.userStatus,
                    item.arrangeStatus,
                    item.isAllowRepeat,
                    item.isControlDate,
                    item.startDate,
                    item.endDate,
                    item.isControlSameTime,
                    item.controlSubmitTime,
                    item.usedExamTimes,
                    item.examTimes
                  )
                " style="color: #52c41a;border: 1px solid #b7eb8f; background: #f6ffed;">
                进行中
              </button>
              <button v-if="tag1(item)" style="color:#666;background:#dddddd;border: 1px solid #b7b6b6;">
                未参加
              </button>
              <button class="tag" v-if="tag2(item)" style="color: #666;background: #dddddd;border: 1px solid #b7b6b6;">
                未开考
              </button>
              <button class="tag" v-if="item.freeLevel == 1" style="color: #318df0;background: #e6f7ff;border: 1px solid #91d5ff;">
                全行公开
              </button>
              <button class="tag" v-if="item.isAllowRepeat === 1" style=" color: #ee7805;background: #fff9f2;border: 1px solid #ee7805;">
                多次考
              </button>
              <button class="tag" v-if="item.isResit === 1" style="color: #fa6a63;background: #fff1f0;border: 1px solid #ffa39e;">
                补考
              </button>
            </div>
            <button class="start" @click="goExam(item)" :disabled="getStartBtnText(item, 0)">
              {{ getStartBtnText(item, 1) }}
            </button>
          </div>
        </router-link>
      </div>
    </van-list>
    <div class="noData" v-if="noData">
      <img src="../../../assets/noData.png" alt="" />
      <p>无数据</p>
    </div>
  </div>
</template>

<script>
import { PullRefresh, List, Tag } from "vant";
import { examList } from "@/api/exam.api.js";
import { PagingCriteria } from "@/utils/index.js";
import dayjs from "dayjs";
import { DateFormat } from "../../../utils/index.js";

export default {
  name: "ExamList",
  components: {
    "van-pull-refresh": PullRefresh,
    "van-list": List,
    "van-tag": Tag
  },
  props: {},
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      finishedText: null,
      data: {
        examType: ""
      },
      pageSize: 10,
      pageNum: 1,
      noData: false
    };
  },
  computed: {},
  created () { },
  methods: {
    getList (statusId, page) {
      this.loading = true;
      this.finished = false;
      this.data.examType = statusId;
      this.pageNum = page ? page.pageNum : this.pageNum;
      this.list = page ? [] : this.list;
      this.data = { ...this.data, ...PagingCriteria(this.pageNum, this.pageSize) };
      examList({ ...this.data })
        .then(res => {
          this.list = [...this.list, ...res.datas];
          this.loading = false;
          this.pageNum++;
          // 如果没有数据，显示暂无数据
          if (this.list.length === 0) {
            this.noData = true;
          }
          if (res.paging.pages === this.pageNum - 1) {
            this.finished = true;
            this.finishedText = this.noData ? "" : "没有更多数据~~";
          }
        })
        .catch(e => { });
    },
    tag1 (item) {
      return (
        (item.userStatus === "NotStarted" &&
          item.isControlDate === 1 &&
          new Date(DateFormat(item.endDate)) < new Date()) ||
        (item.userStatus === "NotStarted" && item.arrangeStatus === "EndTest") ||
        (item.userStatus === "NotStarted" &&
          item.isControlSameTime === 1 &&
          new Date(DateFormat(item.controlSubmitTime)) < new Date())
      );
    },
    tag2 (item) {
      return item.isControlDate === 1 && new Date(item.startDate) > new Date();
    },
    //上拉加载
    onLoad () {
      this.getList(this.data.examType);
    },
    goExam (item) {
      // userExamMapId: item.userExamMapId
      this.$router.push({
        path: "/exam/introduce",
        query: { arrangeId: item.arrangeId }
      });
    },
    releaseDate (e) {
      return dayjs(e).format("MM-DD HH:mm");
    },
    controlSubmitTime (e) {
      return dayjs(e).format("MM-DD HH:mm");
    },
    examDate (e, n) {
      return dayjs(e).format("MM-DD HH:mm") + " ~ " + dayjs(n).format("MM-DD HH:mm");
    },
    submitTime (e) {
      return dayjs(e).format("MM-DD HH:mm");
    },
    //进行中的标签
    setExamProcessStatus (
      userStatus,
      arrangeStatus,
      isAllowRepeat,
      isControlDate,
      startDate,
      endDate,
      isControlSameTime,
      controlSubmitTime,
      usedExamTimes,
      examTimes
    ) {
      let isShowProcessStatus = false;
      let isExpired = false;
      // 开始的考试，根据是否重复考试，判断是否是进行中
      if (arrangeStatus === "StartTest") {
        if (isAllowRepeat === 1) {
          if (userStatus === "NotStarted" || userStatus === "Evaluating") {
            isShowProcessStatus = true;
          } else {
            if (usedExamTimes < examTimes || examTimes === 0) {
              isShowProcessStatus = true;
            }
          }
        } else {
          if (userStatus !== "Done" && userStatus !== "Marking") {
            isShowProcessStatus = true;
          }
        }
      }

      // 已结束的考试，不显示进行中
      if (arrangeStatus === "EndTest") {
        isShowProcessStatus = false;
      }

      if (isControlDate === 1) {
        // 考试未开始，不显示进行中
        if (endDate !== "" && new Date(DateFormat(endDate)) < new Date()) {
          isExpired = true;
        }
        if (isExpired && userStatus === "NotStarted") {
          isShowProcessStatus = false;
        }

        // 考试已过期，不显示进行中
        if (startDate !== "" && new Date(DateFormat(startDate)) > new Date()) {
          isShowProcessStatus = false;
          isExpired = true;
        }
      }

      // 强制统一交卷时间控制
      if (isControlSameTime === 1 && controlSubmitTime !== "") {
        let sameSubmitTime = new Date(DateFormat(controlSubmitTime));
        if (sameSubmitTime <= new Date()) {
          isShowProcessStatus = false;
        }
      }

      if (isExpired && userStatus === "Done") {
        isShowProcessStatus = false;
      }

      return isShowProcessStatus;
    },
    //按钮状态文字  showType:1,返回按钮文本内容，0：返回按钮是否可用
    getStartBtnText (userExamMap, showType) {
      // 开始按钮文本信息
      let startText = "开始考试"; //进入考试
      let btnUnEnabled = false;
      let now = new Date();
      let isExpired = false; // 是否已过期
      if (userExamMap.isResit === 1) {
        //0非补考，1补考
        startText = "补考"; //补考
      }
      if (userExamMap.isControlDate === 1) {
        // 控制进入考试进入时间 0不控制,1控制
        if (userExamMap.startDate !== null && new Date(DateFormat(userExamMap.startDate)) > now) {
          // 考试未开始
          startText = "未开始"; //未开始
          btnUnEnabled = true;
        } else if (
          userExamMap.endDate !== null &&
          new Date(DateFormat(userExamMap.endDate)) < now
        ) {
          // 判断考试是否已过期
          startText = "已过期"; //已过期
          btnUnEnabled = true;
          isExpired = true;
        }
      }
      if (userExamMap.isControlSameTime === 1) {
        //是否控制统一交卷时间，0否1是
        // 强制交卷时间过期处理
        if (
          userExamMap.controlSubmitTime !== null &&
          new Date(DateFormat(userExamMap.controlSubmitTime)) <= now
        ) {
          startText = "已过期";
          btnUnEnabled = true;
          isExpired = true;
        }
      }

      //  如果考试已过期，但用户考试MAP中是完成Done或批阅状态，则用户考试已完成或批阅中
      if (isExpired) {
        if (userExamMap.userStatus === "Done") {
          // 已完成
          startText = "已结束";
        } else if (userExamMap.userStatus === "Marking") {
          // 批阅中
          startText = "批阅中";
        }
      }
      // 考试安排已结束
      // 考试安排的状态，StartTest进行中  EndTest已结束 NotStarted 未发布
      if (userExamMap.arrangeStatus === "EndTest") {
        // 已完成
        startText = "已结束";
        btnUnEnabled = true;
      }
      // 如果可以考试，同时针对用户最近一次考试状态，判断用户考试情况
      if (!btnUnEnabled) {
        if (userExamMap.userStatus === "NotStarted") {
          //未开始
          // 开始考试
          startText = "开始考试";
          if (userExamMap.isResit === 1) {
            // 进入补考
            startText = "补考";
          }
        } else if (userExamMap.userStatus === "Evaluating") {
          //进行中
          // 继续考试
          startText = "继续考试";
        } else if (userExamMap.isAllowRepeat === 1) {
          //是否允许重复考试，0否1是
          // ========允许重复考试.按钮相关处理.Start=============
          if (userExamMap.userStatus === "Marking" || userExamMap.userStatus === "Done") {
            // 再次考试
            startText = "再次考试";
          }
          // 考试次数已用完
          if (userExamMap.examTimes > 0 && userExamMap.examTimes === userExamMap.usedExamTimes) {
            if (userExamMap.userStatus === "Marking") {
              // 批阅中
              startText = "批阅中";
            } else {
              // 已完成
              startText = "已结束";
            }
            // 考试次数已用完，不能再次考试
            btnUnEnabled = true;
          }
          // 已放弃
          if (userExamMap.isAbandoned === 1) {
            //是否放弃考试，0否1是
            // 已完成
            startText = "已结束";
            btnUnEnabled = true;
          }
          // ========允许重复考试.按钮相关处理.End=============
        } else {
          // ========不允许重复考试.按钮相关处理.Start=========
          if (userExamMap.userStatus === "Marking") {
            // 批阅中
            startText = "批阅中";
            btnUnEnabled = true;
          } else {
            // 已完成
            startText = "已结束";
            btnUnEnabled = true;
          }
          // ========不允许重复考试.按钮相关处理.END===========
        }
      }
      if (showType === 0) {
        return btnUnEnabled;
      } else {
        return startText;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.examList {
  .van-list {
    margin: 0 30px;
    .listItme {
      padding: 30px;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.09);
      border-radius: 2px;
      margin-bottom: 30px;
      div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      h4 {
        font-weight: 500;
        color: @color3;
        font-size: 32px;
        margin-bottom: 24px;
        line-height: 50px;
      }
      p {
        color: @color6;
        font-size: 28px;
        margin-bottom: 24px;
        i {
          color: @blueTheme;
          font-weight: 700;
        }
      }
      .score {
        justify-content: start;
        span {
          font-size: 28px;
          color: @blueTheme;
          margin-left: 30px;
        }
      }

      .tags {
        font-size: 24px;
        margin-bottom: 20px;
        height: 40px;
        margin-top: 10px;
        button {
          border-radius: 4px;
          outline: none;
          margin-right: 20px;
        }
      }
      .start {
        width: 200px;
        height: 60px;
        border: none;
        background: @blueTheme;
        font-size: 28px;
        color: #ffffff;
        text-align: center;
        line-height: 60px;
        border-radius: 30px;
        outline: none;
        margin-left: auto;
        &:disabled {
          background: #98c8ec;
        }
      }
    }
  }
  .noData {
    img {
      width: 269px;
      height: 177px;
      display: block;
      margin: 60px auto;
    }
    p {
      font-size: 28px;
      color: #999;
      text-align: center;
    }
  }
}
</style>
