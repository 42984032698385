var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "practiceList" },
    [
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        _vm._l(_vm.list, function(item) {
          return _c("div", { key: item, staticClass: "listItme" }, [
            _c("h4", [_vm._v(_vm._s(item.name))]),
            _c("div", [
              _c("p", [
                _vm._v("安排时间：" + _vm._s(_vm.releaseDate(item.releaseDate)))
              ]),
              _c("p", [
                _vm._v("题目数量：" + _vm._s(item.totalQuestionNum) + "题")
              ])
            ]),
            _c("p", [
              _vm._v(
                "开放时间：" +
                  _vm._s(_vm.examDate(item.startDate, item.endDate))
              )
            ]),
            _c("p", [
              _c("span", [_vm._v("学习进度：")]),
              _c("i", [_vm._v(_vm._s(item.answerQuestionNum))]),
              _c("em", [_vm._v("/" + _vm._s(item.totalQuestionNum))])
            ]),
            _c("div", [
              item.userStatus == "NotStarted"
                ? _c(
                    "button",
                    {
                      staticClass: "tag",
                      staticStyle: {
                        color: "#318df0",
                        background: "#e6f7ff",
                        border: "1px solid #91d5ff"
                      }
                    },
                    [_vm._v(" 未开始 ")]
                  )
                : _vm._e(),
              item.userStatus == "Evaluating"
                ? _c("button", { staticClass: "tag" }, [_vm._v("进行中")])
                : _vm._e(),
              item.userStatus == "Done"
                ? _c(
                    "button",
                    {
                      staticClass: "tag",
                      staticStyle: {
                        color: "#666",
                        background: "#dddddd",
                        border: "1px solid #b7b6b6"
                      }
                    },
                    [_vm._v(" 已结束 ")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "start",
                  attrs: { disabled: _vm.btnText(item, 0) },
                  on: {
                    click: function($event) {
                      return _vm.goExam(item.id)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.btnText(item, 1)) + " ")]
              )
            ])
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }