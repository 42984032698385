<template>
  <div class="practiceList">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="listItme" v-for="item in list" :key="item">
        <!-- <router-link :to="{path:'/exam/exerciseList',query: {id: item.id}}"> -->
        <h4>{{ item.name }}</h4>
        <div>
          <p>安排时间：{{ releaseDate(item.releaseDate) }}</p>
          <p>题目数量：{{ item.totalQuestionNum }}题</p>
        </div>
        <p>开放时间：{{ examDate(item.startDate, item.endDate) }}</p>
        <p>
          <span>学习进度：</span><i>{{ item.answerQuestionNum }}</i
          ><em>/{{ item.totalQuestionNum }}</em>
        </p>
        <div>
          <button
            v-if="item.userStatus == 'NotStarted'"
            style="color: #318df0;background: #e6f7ff;border: 1px solid #91d5ff;"
            class="tag"
          >
            未开始
          </button>
          <button v-if="item.userStatus == 'Evaluating'" class="tag">进行中</button>
          <button
            v-if="item.userStatus == 'Done'"
            class="tag"
            style="color:#666;background:#dddddd;border: 1px solid #b7b6b6;"
          >
            已结束
          </button>
          <button class="start" @click="goExam(item.id)" :disabled="btnText(item, 0)">
            {{ btnText(item, 1) }}
          </button>
        </div>
        <!-- </router-link> -->
      </div>
    </van-list>
  </div>
</template>
<script>
import { PullRefresh, List, Tag } from "vant";
import { practiceList } from "@/api/exam.api.js";
import dayjs from "dayjs";
import { DateFormat } from "../../../utils/index.js";

export default {
  name: "PracticeList",
  components: {
    "van-list": List,
    "van-tag": Tag
  },
  props: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      data: {
        keyWords: "",
        pageNum: 1,
        pageSize: 5,
        status: 0 // 0:全部 1:未开始 2:进行中 3:已结束
      }
    };
  },
  watch: {},
  created() {
    // this.getList()
  },
  methods: {
    releaseDate(e) {
      return dayjs(e).format("MM-DD HH:mm");
    },
    examDate(e, n) {
      if (e !== null || n !== null) {
        return dayjs(e).format("MM-DD HH:mm") + " ~ " + dayjs(n).format("MM-DD HH:mm");
      }
    },
    /**
     * type:0.按钮状态, 1.按钮文字
     */
    btnText(e, type) {
      let text = "开始答题";
      let btnStatu = false;

      if (e.startDate && new Date(DateFormat(e.startDate)) >= new Date()) {
        // 考试未开始
        text = "未开始"; //未开始
        btnStatu = true;
      } else if (e.endDate && new Date(DateFormat(e.endDate)) < new Date()) {
        // 判断考试是否已过期
        text = "已过期"; //已过期
        btnStatu = true;
      } else {
        if (e.userStatus === "NotStarted") {
          text = "开始答题";
        } else if (e.userStatus === "Evaluating") {
          text = "继续答题";
        } else if (e.userStatus === "Done") {
          btnStatu = true;
          text = "已结束";
        }
      }
      if (type == 1) {
        return text;
      } else {
        return btnStatu;
      }
    },
    getList(statusId, page) {
      this.loading = true;
      this.finished = false;
      this.data.status = statusId;
      this.data.pageNum = page ? page.pageNum : this.data.pageNum;
      this.list = page ? [] : this.list;
      practiceList({ ...this.data })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.list = [...this.list, ...res.listBean];
            this.data.pageNum++;
            // 如果没有数据，显示暂无数据
            if (this.list.length === 0) {
              this.noData = true;
            }
            if (res.pageTotal === this.data.pageNum - 1) {
              this.finished = true;
              this.finishedText = this.noData ? "" : "没有更多数据~~";
            }
          }
        })
        .catch(err => {});
    },
    //上拉加载
    onLoad() {
      this.getList(this.data.status);
    },
    goExam(id) {
      this.$router.push({ path: "/exam/exerciseList", query: { id: id } });
    }
  }
};
</script>

<style lang="less" scoped>
.practiceList {
  .van-list {
    margin: 0 30px;
    .listItme {
      padding: 30px;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.09);
      border-radius: 2px;
      margin-bottom: 30px;
      div {
        display: flex;
        justify-content: space-between;
      }
      h4 {
        font-weight: 500;
        color: @color3;
        font-size: 32px;
        margin-bottom: 24px;
        line-height: 50px;
      }
      p {
        color: @color6;
        font-size: 28px;
        margin-bottom: 24px;
        i {
          color: @blueTheme;
          font-weight: 700;
        }
      }
      .tag {
        font-size: 24px;
        color: #52c41a;
        border: 1px solid #b7eb8f;
        background: #f6ffed;
        border-radius: 4px;
        height: 40px;
        margin-top: 10px;
        outline: none;
      }
      .start {
        width: 200px;
        height: 60px;
        border: none;
        background: @blueTheme;
        font-size: 28px;
        color: #ffffff;
        text-align: center;
        line-height: 60px;
        border-radius: 30px;
        outline: none;
        &:disabled {
          background: #98c8ec;
          color: #fff;
        }
      }
    }
  }
}
</style>
